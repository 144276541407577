@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body{
    font-family: "Inter"
}

@media (min-width: 992px) and (max-width: 1199px){
    .group.relative{
        margin-left: 25px!important
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;